'use strict';

function gsapAnimationInitialization() {
    $('.js-cart-account-benefits-animate').each(function () {
        var animationTiming = parseInt($(this).data('animation-timing'));
        var messages = $('.free-shipping-message');
        var currentMessage = 0;
        var nextMessage = currentMessage + 1 < messages.length ? currentMessage + 1 : 0;
        setInterval(function () {
            var cartBenefitsAnimate = gsap.timeline({ //eslint-disable-line
                onComplete: function () {
                    currentMessage = nextMessage;
                    nextMessage = currentMessage + 1 < messages.length ? currentMessage + 1 : 0;
                }
            });
            cartBenefitsAnimate
                .fromTo(messages[currentMessage], { opacity: 1, duration: 0.3, ease: 'power2.out' }, { opacity: 0})
                .fromTo(messages[nextMessage], { x: 30, opacity: 0, duration: 0.3, delay: 0.3, ease: 'power2.out' }, { x: 0, opacity: 1});
        }, animationTiming);
    });

    $('.js-animate-account-benefits-icons').each(function () {
        var icons = $('.icons-element');
        var currentIcon = 0;
        var nextIcon = currentIcon + 1 < icons.length ? currentIcon + 1 : 0;
        setInterval(function () {
            var iconBenefitsAnimate = gsap.timeline({ //eslint-disable-line
                onComplete: function () {
                    currentIcon = nextIcon;
                    nextIcon = currentIcon + 1 < icons.length ? currentIcon + 1 : 0;
                }
            });
            iconBenefitsAnimate
                .fromTo(icons[currentIcon], { opacity: 1, duration: 0.3, delay: 2.7, ease: 'power2.out' }, { opacity: 0})
                .fromTo(icons[nextIcon], {opacity: 0, duration: 0.3, delay: 3, ease: 'power2.out'}, {opacity: 1});
        }, 3000);
    });
}

module.exports = {
    gsapAnimationInitialization: gsapAnimationInitialization
};
