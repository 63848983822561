'use strict';

var Swiper = require('swiper/cjs/components/core/core-class.js').default;
var pagination = require('swiper/cjs/components/pagination/pagination.js').default;
var navigation = require('swiper/cjs/components/navigation/navigation.js').default;
var autoplay = require('swiper/cjs/components/autoplay/autoplay.js').default;
var EffectFade = require('swiper/cjs/components/effect-fade/effect-fade.js').default;

Swiper.use(pagination);
Swiper.use(navigation);
Swiper.use(autoplay);
Swiper.use(EffectFade);
function swiperInitialization() {
    $('.js-swiper-delivery').each(function () {
        var swiper = new Swiper('.swiper-ship', { //eslint-disable-line
            direction: 'horizontal',
            freeMode: true,
            resistanceRatio: 0,
            observer: 'true',
            observerParents: 'true',
            slidesPerView: 'auto',
            watchSlidesVisibility: true,

            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },

            scrollbar: {
                el: 'swiper-scrollbar'
            }
        });
    });
    $('.js-swiper-instore').each(function () {
        var swiper = new Swiper('.swiper-instore', { //eslint-disable-line
            direction: 'horizontal',
            freeMode: true,
            resistanceRatio: 0,
            observer: 'true',
            observerParents: 'true',
            slidesPerView: 'auto',
            watchSlidesVisibility: true,

            navigation: {
                nextEl: '.swiper-button-next-instore',
                prevEl: '.swiper-button-prev-instore'
            },
            scrollbar: {
                el: 'swiper-scrollbar'
            }
        });
    });

    $('.js-swiper-wg').each(function () {
        var swiper = new Swiper('.swiper-wg', { //eslint-disable-line
            direction: 'horizontal',
            freeMode: true,
            resistanceRatio: 0,
            observer: 'true',
            observerParents: 'true',
            slidesPerView: 'auto',
            watchSlidesVisibility: true,

            navigation: {
                nextEl: '.swiper-button-next-wg',
                prevEl: '.swiper-button-prev-wg'
            },
            scrollbar: {
                el: 'swiper-scrollbar'
            }
        });
    });

    $('.js-swiper-edd-split').each(function () {
    	var swiper = new Swiper('.swiper-edd-split', { //eslint-disable-line
            direction: 'horizontal',
            freeMode: true,
            resistanceRatio: 0,
            observer: 'true',
            observerParents: 'true',
            slidesPerView: 2.5,

            navigation: {
                nextEl: '.swiper-button-next-edd',
                prevEl: '.swiper-button-prev-edd'
            },
            scrollbar: {
                el: 'swiper-scrollbar'
            },
            breakpoints: {
                960: {
                    slidesPerView: 3.5
                }
            }
        });
    });
}

module.exports = {
    swiperInitialization: swiperInitialization
};
