'use strict';

function selectFloat() {
    $('select').on('change', function () {
        if ($(this).find('option:selected').val() === null || $(this).find('option:selected').val() === '') {
            $(this).removeClass('valid');
        } else {
            $(this).addClass('valid');
        }
    });

    if ($('select').find('option:selected').val() === null || $('select').find('option:selected').val() === '') {
        $('select').removeClass('valid');
    } else {
        $('select').addClass('valid');
    }
}

// This is the custom utility for character count implementation. If needed in site any where, just add class
// 'charCountField' to the textarea and put a span for count message with id as count_message.

function charCount() {
    $('.js-charCountField').each(function () {
        var $this = $(this);
        var maxChar = parseInt($this.attr('maxlength'));

        $this.on('keyup', function () {
            var textLength = $this.val().length;
            $this.closest('.form-group').find('.js-char-count-msg').html(textLength + '/' + maxChar);
        });
    });
}

function stopTooltipEventPropagation() {
    $('.js-tooltip').on('click', function (e) {
        e.stopPropagation();
    });
}

function existingEmailMessageEvent() {
    $('.js-login-form .register-message a').on('click', function (e) {
        e.preventDefault();
        $(this).parents('.modal-login-content').find('#register-tab').trigger('click');
    });

    $('.js-login-form .login-message a').on('click', function (e) {
        e.preventDefault();
        $(this).parents('.modal-login-content').find('#login-tab').trigger('click');
    });
}

module.exports = {
    selectFloat: selectFloat,
    charCount: charCount,
    stopTooltipEventPropagation: stopTooltipEventPropagation,
    existingEmailMessageEvent: existingEmailMessageEvent
};
