// window.jQuery = require('jquery');
// window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('base/components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('base/components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('base/components/search'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/formFields'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/swiperInit'));
    processInclude(require('./components/animation'));
});

require('base/thirdParty/bootstrap');
require('base/components/spinner');
require('simplebar');
